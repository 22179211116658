import Vue from 'vue'
import App from './App.vue'
import router from './router'

// 使用 ElementUI 组件库步骤
// 安装依赖包 : npm install element-ui -S
// import ElementUI from 'element-ui' // 导入 ElementUI 组件库
// import 'element-ui/lib/theme-chalk/index.css'
// Vue.use(ElementUI) // 配置 Vue 组件


import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.config.productionTip = false

new Vue({
  router, // 挂载路由
  render: h => h(App) // 通过 render 函数把根组件渲染到首页上,
}).$mount('#app')
