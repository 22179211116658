<template>
  <div class="container">
    <van-row>
      <img class="logo" style="float: none" alt="365IT.Service Logo" src="../assets/logo.png" width="200" height="58">
    </van-row>

    <van-row>
      <van-swipe class="swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item><img src='../assets/index1.jpg'/></van-swipe-item>
        <van-swipe-item><img src='../assets/index2.jpg'/></van-swipe-item>
        <van-swipe-item><img src='../assets/index3.jpg'/></van-swipe-item>
      </van-swipe>
    </van-row>

    <van-row>
      <van-form validate-first @failed="onFailed" class="form">
        <van-field
          v-model="form.customer_name"
          name="pattern"
          placeholder="请输入企业名称（可选）"
        >
          <template v-slot:label>
            <span class="label"> 企业名称</span>
          </template>
        </van-field>

        <!--        placeholder="请输入所在地址"-->
        <van-field
          v-model="form.address"
          name="pattern"
          placeholder="请输入联系地址方便上门查看故障（可选）"
        >
          <template v-slot:label>
            <span class="label">联系地址</span>
          </template>
        </van-field>

        <van-field
          readonly
          clickable
          name="calendar"
          :value="value"
          placeholder="请选择期望联系时间（可选）"
          @click="showCalendar = true"
        >
          <template v-slot:label>
            <span class="label"> 联系时间</span>
          </template>
        </van-field>
        <van-calendar v-model="showCalendar" @confirm="onConfirm"/>

        <van-field
          v-model="form.name"
          name="pattern"
          placeholder="请输入联系人方便工程师联系 （可选）"
        >
          <template v-slot:label>
            <span class="label"> 联系人</span>
          </template>
        </van-field>

        <van-field
          v-model="form.phone"
          required
          type="tel"
          placeholder="请输入联系电话 （必选）"
          :rules="[{ pattern, message: '请输入联系电话方便与您沟通' }]"
        >
          <template v-slot:label>
            <span class="label"> 联系电话</span>
          </template>
        </van-field>

        <!--        <van-field-->
        <!--          v-if="form.phone"-->
        <!--          v-model="form.sms"-->
        <!--          required-->
        <!--          center-->
        <!--          clearable-->
        <!--          label="短信验证码"-->
        <!--          placeholder="请输入短信验证码"-->
        <!--          :rules="[{ pattern, message: '请输入短信验证码，有效期 5 分钟' }]"-->
        <!--        >-->
        <!--          <template v-slot:label>-->
        <!--            <span class="label"> 短信验证码</span>-->
        <!--          </template>-->
        <!--          <template #button>-->
        <!--            <van-button icon="shield-o" size="small" type="primary" @click="handleSendSMSCode">发送验证码</van-button>-->
        <!--          </template>-->
        <!--        </van-field>-->

        <van-field
          v-model="form.desc"
          rows="2"
          autosize
          type="textarea"
          maxlength="100"
          placeholder="请输入故障描述方便工程师判断故障范围"
          show-word-limit
        >
          <template v-slot:label>
            <span class="label"> 故障描述</span>
          </template>
        </van-field>

        <div style="margin: 16px;">
          <van-button icon="list-switch" round block type="info" native-type="submit">一键报修</van-button>
        </div>
      </van-form>
    </van-row>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value1: '',
      value2: '',
      value3: '',
      pattern: /\d{6}/,
      images: ['/assets/index1.jpg', '/assets/index2.jpg', '/assets/index3.jpg'],
      // images: [
      //   'https://img01.yzcdn.cn/vant/apple-1.jpg',
      //   'https://img01.yzcdn.cn/vant/apple-2.jpg'
      // ],
      value: '',
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(2021, 0, 17),
      showCalendar: false,
      form: {
        customer_name: '',
        address: '',
        name: '',
        expected_date: '',
        // expected_time: '',
        phone: '',
        sms: '', // 验证码
        desc: '' // 故障描述
      }
    }
  },
  methods: {

    onConfirm (date) {
      console.log(date.getFullYear())
      this.value = `${date.getFullYear()} 年 ${date.getMonth() + 1} 月 ${date.getDate()} 日`
      this.showCalendar = false
    },

    // 校验函数返回 true 表示校验通过，false 表示不通过
    validator (val) {
      return /1\d{10}/.test(val)
    },
    // 异步校验函数返回 Promise
    asyncValidator (val) {
      return new Promise((resolve) => {
        Toast.loading('验证中...')

        setTimeout(() => {
          Toast.clear()
          resolve(/\d{6}/.test(val))
        }, 1000)
      })
    },

    // 发送短信验证码
    handleSendSMSCode (test) {
      console.log('test', test)
    },
    onFailed (errorInfo) {
      console.log('failed', errorInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {

  .logo {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    //background-color: #39a9ed;
    img {
      width: 100%;
    }
  }

  //
  //.alert {
  //  //width: 80%;
  //  margin-bottom: 20px;
  //}

  .form {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;

    .label {
      width: 72px;
      display: inline-block; // 变成行块元素
      text-align: justify; // 两端对齐
      text-align-last: justify; // 两端对齐
      font-size: 14px;
      color: #1b8afa;
    }

  }
}
</style>
